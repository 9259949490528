<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 mb-3">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Plataformas</h1>
                    <p class="desc mt-0 mb-1">Listado de plataformas.</p>
                </v-alert>
            </v-col>
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field v-model="search" color="#8061c2" label="Buscar" append-icon="mdi-magnify" single-line
                        hide-details outlined class="mb-2"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="px-md-2 px-0">
                    <v-select v-model="filterType" label="Tipo de Plataforma" :items="filterOptions" outlined
                        hide-details></v-select>
                </v-col>
                <v-col cols="12" md="4" class="px-0">
                    <v-select v-model="sortDesc" :items="sortOptions" label="Ordenar" outlined hide-details></v-select>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="filteredPlatforms" :search="search" :loading="loading"
                :footer-props="{ itemsPerPageOptions: [50, 100, 200, 300, -1], showFirstLastPage: true}"
                class="elevation-1 mx-3 px-2 py-4">
                <template v-slot:item.count="{ item }">
                    {{ item.count.count }}
                </template>

                <template v-slot:item.platform="{ item }">
                    {{ item.platform }}
                </template>


                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" :loading="item.loading"
                                @click="toggleAdultContent(item)">
                                <v-icon color="#d33" v-if="item.count.is_adult">mdi-lock</v-icon>
                                <v-icon v-else color="#28b761 ">mdi-lock-open</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="item.count.is_adult">Desmarcar como adultos</span>
                        <span v-else>Marcar como adultos</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.monetization="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" :loading="item.loading" @click="showDialog(item)">
                                <v-icon color="#757575" v-if="item.count.is_monetized == null">mdi-cash</v-icon>
                                <v-icon color="#28b761 " v-else-if="item.count.is_monetized">mdi-cash</v-icon>
                                <v-icon v-else color="#d33">mdi-cash</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="item.count.is_monetized == null">Sin configurar</span>
                        <span v-else-if="item.count.is_monetized">Monetizando</span>
                        <span v-else>No monetiza</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-col>


        <!-- Modal para editar usuario -->
        <v-dialog transition="dialog-top-transition" max-width="550" v-model="show">
            <v-card class="p-0 m-0">
                <v-alert :value="true" border="right" colored-border color="#8e84c0" elevation="0"
                    class="pl-4 pr-5 mb-0">
                    <v-col class="px-1 pt-0 m-0 text-left">
                        <v-row no-gutters>
                            <v-col cols="12" class="px-0 pt-0 mt-0 text-left">

                                <span class="title-emails">
                                    Editando monetización de {{ platformName.charAt(0).toUpperCase() + platformName.slice(1) }}
                                </span>
                                <br>
                                <span  class="monetization">
                                  Plataforma: {{ platformName }}
                                </span>

                                <v-divider class="mt-2"></v-divider>

                                <div class="mt-5">
                                    <div class="d-flex align-center">
                                      <v-avatar class="grey lighten-2 rounded-pill mr-3">
                                        <v-icon color="8061c2" size="30">
                                          mdi-currency-usd
                                        </v-icon>
                                      </v-avatar>
                                      <div>
                                        <h5 class="title-monetization " >Habilitar Monetización</h5>
                                        <p class="desc-monetization mb-0">Permite que {{  platformName }} monetice por clicks.</p>
                                      </div>
                                      <v-switch
                                        v-model="isMonetized"
                                        :value="isMonetized"
                                        :false-value="false"
                                        :true-value="true"
                                        inset
                                        color="success"
                                        class="ml-auto"
                                      >
                                      </v-switch>
                                    </div>

                                    <!-- <v-switch
                                        v-model="isMonetized"
                                        :label="isMonetized ? 'Monetizado' : 'No Monetizado'"
                                        :value="isMonetized"
                                        :false-value="false"
                                        :true-value="true"
                                        inset
                                        color="success"
                                        class="mt-3"
                                    >
                                    </v-switch> -->
                                </div>

                            </v-col>
                        </v-row>
                    </v-col>

                    <div class="text-center">
                        <v-btn @click="updatedMonetization()" color="#8e84c0"
                            class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                            <v-icon left color="white">mdi-cash-sync</v-icon>
                            Actualizar
                        </v-btn>
                    </div>
                </v-alert>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from '@/axios.js';

export default {
    data() {
        return {
            headers: [
                { text: "Plataforma", value: "platform", sortable: true },
                { text: "Cantidad", value: "count", sortable: true },
                { text: "Acciones", value: "actions", sortable: false },
                { text: "Monetizacion", value: "monetization", sortable: false },
            ],
            loading: false,
            platforms: [],
            search: '',
            filterType: null,
            sortDesc: true,
            show: false,
            platformName: '',
            isMonetized: false,
            sortOptions: [
                { text: "Mayor a menor", value: true },
                { text: "Menor a mayor", value: false }
            ],
            filterOptions: [
                { text: "Todas", value: null },
                { text: "Propias", value: true },
                { text: "No propias", value: false }
            ],
        };
    },
    computed: {
        filteredPlatforms() {
            let filtered = this.platforms.filter(platform =>
                platform.platform.toLowerCase().includes(this.search.toLowerCase())
            );

            if (this.filterType !== null) {
                filtered = filtered.filter(platform => platform.count.is_own === this.filterType);
            }
            filtered.sort((a, b) => {
                return this.sortDesc ? b.count.count - a.count.count : a.count.count - b.count.count;
            });

            return filtered;
        }
    },

    methods: {
        async fetchPlatforms() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/platforms-count');
                this.platforms = Object.entries(response.data).map(([platform, count]) => ({
                    platform,
                    count,
                    loading: false,
                }));
            } catch (error) {
                console.error("Error fetching platform counts:", error);
            } finally {
                this.loading = false;
            }
        },

        async toggleAdultContent(platform) {
            const action = platform.count.is_adult ? 'quitar' : 'marcar';
            const actionToast = platform.count.is_adult ? 'quitada' : 'marcada';
            const confirmationText = `¿Estás seguro de que quieres ${action} esta plataforma como contenido para adultos?`;
            this.$swal({
                title: `${action.charAt(0).toUpperCase() + action.slice(1)} como contenido para adultos`,
                text: confirmationText,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    platform.loading = true;
                    try {
                        const response = await axios.post('/admin/mark-adult-platform', {
                            name_platform: platform.platform,
                            is_adult: !platform.count.is_adult
                        });
                        this.fetchPlatforms();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: '¡Hecho!',
                                text: `La plataforma ha sido ${actionToast} como contenido para adultos.`,
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Hubo un problema al completar la operación. Por favor, intenta de nuevo.');
                    } finally {
                        platform.loading = false;
                    }
                }
            });
        },

        updatedMonetization() {
            axios.post('/admin/mark-monetizable-platform', {
                platform: this.platformName,
                monetized: this.isMonetized
            }).then(response => {
                if (response.data.success) {
                    this.$vs.notify({
                        title: '¡Hecho!',
                        text: `La monetización de ${this.platformName} ha sido actualizada.`,
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                    this.show = false;
                    this.fetchPlatforms();
                }
            }).catch(error => {
                console.error(error);
                this.$toast.error('Hubo un problema al completar la operación. Por favor, intenta de nuevo.');
            });
        },

        showDialog(item) {
            if (item.count.is_monetized == null) {
                this.isMonetized = false;
            } else {
                this.isMonetized = item.count.is_monetized;
            }
            this.platformName = item.platform;
            this.show = true;
        },
    },
    mounted() {
        this.fetchPlatforms();
    }
};
</script>

<style scoped>
.title-emails {
  color: #8061c2;
  font-weight: 700;
  font-size: 20px;
}

.harmony-name {
  color: #8061c2;
  font-weight: 800;
  font-size: 18px;
}


.title-monetization {
  font-weight: 500;
  font-size: 0.9rem;
}


.desc-monetization {
 color: #757575;
  font-size: 0.7rem;
}

.monetization {
 color: #757575;
  font-size: 1rem;
}
</style>